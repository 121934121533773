import React, { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';

import Dialog from '../../../../../../common/components/ConfirmationDialog/Dialog';
import { RestaurantSelect } from '../../../../../../common/components/RestaurantSelect/RestaurantSelect';
import { selectStyle } from '../../../../../../common/components/Select/select-style';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useCreateInventoryCount } from '../../../../../../services/inventory-counts';
import { useRestaurantsData } from '../../../../../../services/restaurants';
import { useInventoryCountsContext } from '../../../../context/hooks/use-inventory-counts-context.hook';
import { InventoryCountsFormValues } from '../../../../model/inventory-counts-form-values';

export const StartInventory = () => {
  const { restaurants } = useRestaurantsData();
  const translations = useTranslations();
  const defaultRestaurantId = restaurants.length === 1 ? restaurants[0].id : null;
  const [restaurantId, setRestaurantId] = useState<string | null>(defaultRestaurantId);
  const { activeStep, setActiveStep, setCurrentInventoryCountId, setRestaurantName } = useInventoryCountsContext();
  const confirmStartCancelRef = useRef(null);
  const startInventoryConfirmModal = useDisclosure();
  const { mutate: createInventoryCount, isLoading: isCreatingInventoryCount } = useCreateInventoryCount();
  const { reset } = useFormContext<InventoryCountsFormValues>();

  const handleStartInventory = () => {
    if (restaurants.length > 1 && !restaurantId) {
      toast.error(<b>{translations('inventory_counts_start_inventory_missing_restaurant_error')}</b>);
    } else {
      startInventoryConfirmModal.onOpen();
    }
  };

  const handleConfirm = () => {
    if (restaurantId) {
      createInventoryCount(
        { restaurantId },
        {
          onSuccess: (data) => {
            toast.success(translations('inventory_counts_inventory_count_successfully_started'));
            setCurrentInventoryCountId(data.id);
            setActiveStep(activeStep + 1);
            setRestaurantName(data.restaurant.restaurantName);
            reset();
          },
          onError: () => {
            startInventoryConfirmModal.onClose();
          },
        },
      );
    }
  };

  return (
    <>
      <Flex justifyContent="flex-end" w={'100%'} px={[2, 2, 4, 4]} wrap={'wrap'}>
        {restaurants.length > 1 ? (
          <Box w={['100%', '100%', '300px', '300px']} p={1}>
            <RestaurantSelect
              userRestaurants={restaurants}
              restaurantId={restaurantId}
              setRestaurantId={setRestaurantId}
              resetRestaurantId={() => setRestaurantId(defaultRestaurantId)}
              styles={selectStyle}
            />
          </Box>
        ) : null}
        <Box w={['100%', '100%', '200px', '200px']} p={1}>
          <Button mr={0} w={'100%'} variant={'orangeSolid'} onClick={handleStartInventory}>
            {translations('inventory_counts_start_inventory')}
          </Button>
        </Box>
      </Flex>
      <Dialog
        isLoading={isCreatingInventoryCount}
        leastDestructiveRef={confirmStartCancelRef}
        isOpen={startInventoryConfirmModal.isOpen}
        onClose={startInventoryConfirmModal.onClose}
        onConfirm={handleConfirm}
        confirmText={translations('inventory_counts_start_inventory_confirm_button_label')}
        cancelText={translations('cancel')}
      >
        <Text fontSize={'20px'}>{translations('inventory_counts_start_inventory_confirm_text')}</Text>
      </Dialog>
    </>
  );
};
