import { Routes } from 'config/routes';
import { Role } from 'contexts/SessionContext/SessionContext';

import { MenuItem } from '../model/MenuItem.model';

const MENU_ITEMS: MenuItem[] = [
  {
    translationKey: 'menu_home',
    iconName: 'menu_home.svg',
    route: Routes.HOME_PATH,
    roles: [Role.MANAGER, Role.EMPLOYEE],
  },
  {
    translationKey: 'menu_infopoint',
    iconName: 'menu_infopoint.svg',
    route: Routes.INFOPOINT,
    roles: [Role.MANAGER, Role.EMPLOYEE],
  },
  {
    translationKey: 'menu_locations',
    iconName: 'menu_locations.svg',
    route: Routes.LOCATIONS_PATH,
    roles: [Role.MANAGER],
  },
  {
    translationKey: 'menu_inventory_counts',
    iconName: 'menu_inventory_counts.svg',
    route: Routes.INVENTORY_COUNTS_PATH,
    roles: [Role.MANAGER],
  },
  {
    translationKey: 'menu_relevos',
    iconName: 'menu_relevos.svg',
    roles: [Role.MANAGER, Role.EMPLOYEE],
    subSections: [
      {
        translationKey: 'menu_relevo_assign',
        iconName: 'menu_relevos_assign.svg',
        route: Routes.ASSIGN_RELEVO_PATH,
        roles: [Role.MANAGER, Role.EMPLOYEE],
      },
      {
        translationKey: 'menu_relevo_return',
        iconName: 'menu_relevos_return.svg',
        route: Routes.RETURN_RELEVO_PATH,
        roles: [Role.MANAGER, Role.EMPLOYEE],
      },
      {
        translationKey: 'menu_relevo_quick_contacts',
        iconName: 'menu_relevos_quick_contacts.svg',
        route: Routes.QUICK_CONTACTS_PATH,
        roles: [Role.MANAGER],
      },
      {
        translationKey: 'menu_report_damaged_items',
        iconName: 'menu_relevos_report_damage.svg',
        route: Routes.REPORT_DAMAGED_ITEMS,
        roles: [Role.MANAGER, Role.EMPLOYEE],
      },
    ],
  },
  {
    translationKey: 'menu_reports',
    iconName: 'menu_reports.svg',
    route: Routes.REPORTS_PATH,
    roles: [Role.MANAGER],
  },
  {
    translationKey: 'menu_history',
    iconName: 'menu_history.svg',
    route: Routes.HISTORY_PATH,
    roles: [Role.MANAGER, Role.EMPLOYEE],
  },
  {
    translationKey: 'menu_relevo_card_upgrade',
    iconName: 'menu_card_upgrade.svg',
    route: Routes.RELEVO_CARD_UPGRADE,
    roles: [Role.MANAGER, Role.EMPLOYEE],
  },
  {
    translationKey: 'menu_inventory',
    iconName: 'menu_inventory.svg',
    route: Routes.INVENTORY_PATH,
    roles: [Role.MANAGER, Role.EMPLOYEE],
  },
  {
    translationKey: 'menu_contact',
    iconName: 'menu_contact.svg',
    route: Routes.CONTACT_PATH,
    roles: [Role.MANAGER, Role.EMPLOYEE],
  },
  {
    translationKey: 'menu_deposit',
    iconName: 'menu_deposit.svg',
    route: Routes.DEPOSIT_PATH,
    roles: [Role.MANAGER, Role.EMPLOYEE],
  },
  {
    translationKey: 'menu_profile',
    iconName: 'menu_profile.svg',
    route: Routes.PROFILE_PATH,
    roles: [Role.MANAGER, Role.EMPLOYEE],
  },
];

export default MENU_ITEMS;
