import { Box, Flex, Step, Stepper, Text } from '@chakra-ui/react';

import { PageSectionHeading } from '../../../../common/components/PageSectionHeading/PageSectionHeading';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useInventoryCountsContext } from '../../context/hooks/use-inventory-counts-context.hook';
import { steps } from './utils/steps.consts';
import { InventoryCountsStepperStep } from './InventoryCountsStepperStep';

export const InventoryCountsStepper = () => {
  const translations = useTranslations();
  const { activeStep } = useInventoryCountsContext();
  const activeStepTitle = translations(steps[activeStep].title);
  const activeStepDescription = translations(steps[activeStep].description);
  return (
    <>
      <Box pl={2} pt={7} pb={3}>
        <PageSectionHeading>{translations('inventory_counts_how_it_works_heading')}</PageSectionHeading>
      </Box>
      <Box px={4} pb={[0, 0, 0, 4]} pt={2} width={'100%'}>
        <Stepper size="lg" colorScheme={'orange'} index={activeStep} width={['100%', '100%', '100%', '75%']}>
          {steps.map((step, index) => (
            <Step key={index}>
              <InventoryCountsStepperStep
                title={translations(steps[index].title)}
                description={translations(steps[index].description)}
              />
            </Step>
          ))}
        </Stepper>
        <Box display={['block', 'block', 'block', 'none']}>
          <Flex alignItems={'start'} textAlign={'left'} direction={'column'} py={2}>
            <Text fontSize={'14px'} fontWeight={'bold'}>
              {activeStepTitle}
            </Text>
            <Text fontSize={'14px'}>{activeStepDescription}</Text>
          </Flex>
        </Box>
      </Box>
    </>
  );
};
