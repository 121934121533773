import React from 'react';
import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';

import { PageSectionHeading } from '../../../../../../../common/components/PageSectionHeading/PageSectionHeading';
import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { benefits } from './utils/benefits.consts';

export const YourBenefits = () => {
  const translations = useTranslations();
  const getIconPath = (iconName: string) => require(`../../../../../../../assets/icons/${iconName}`);
  return (
    <Box p={2}>
      <PageSectionHeading>{translations('inventory_counts_your_benefits_heading')}</PageSectionHeading>
      <Flex wrap={'wrap'} pt={3}>
        {benefits.map((benefit, index) => (
          <Box p={[1, 1, 1, 2]} width={['50%', '50%', '25%', '20%']} key={index}>
            <Flex direction={'column'} h={'140px'} boxShadow="md" rounded="md">
              <Center h="60%">
                <Image src={getIconPath(benefit.icon)} boxSize="80px" alt="Tile icon" />
              </Center>
              <Box bg={'grey.10'} h={'100%'} py={2}>
                <Text fontSize={'14px'}>{translations(benefit.description)}</Text>
              </Box>
            </Flex>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};
