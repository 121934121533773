import React, { useMemo } from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';

import { PageSectionHeading } from '../../../../../../../common/components/PageSectionHeading/PageSectionHeading';
import { TableType } from '../../../../../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../../../../../common/components/Table/Table';
import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { useReview } from '../../../InventoryCountsThirdStep/components/Review/hooks/use-review.hook';

export const ReviewYourCountSummary = () => {
  const translations = useTranslations();
  const { isLoading, inventoryTotalItems, inventoryScannedItems, inventoryMissingItems } = useReview();

  const data: { stock: string; count: number }[] = [
    { stock: translations('inventory_counts_should_stock_label'), count: inventoryTotalItems || 0 },
    { stock: translations('inventory_counts_actual_stock_label'), count: inventoryScannedItems || 0 },
    { stock: translations('inventory_counts_total_missing_label'), count: inventoryMissingItems || 0 },
  ];

  const columns = useMemo<ColumnDef<{ stock: string; count: number }>[]>(
    () => [
      { accessorKey: 'stock', header: translations('inventory_counts_stock_table_header') },
      { accessorKey: 'count', header: translations('inventory_counts_count_table_header') },
    ],
    [translations],
  );
  return (
    <VStack alignItems={'start'} p={2}>
      <PageSectionHeading>{translations('inventory_counts_review_you_count_heading')}</PageSectionHeading>
      <Text fontSize={14} textAlign={'left'} py={3}>
        {translations('inventory_counts_review_you_count_summary')}
      </Text>
      <Box w={'100%'}>
        <Table
          variant={'inventoryItemReview'}
          type={TableType.SIMPLE}
          isLoading={isLoading}
          data={data}
          columns={columns}
          withBorder
        />
      </Box>
    </VStack>
  );
};
