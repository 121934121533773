import React, { useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckIcon } from '@chakra-ui/icons';
import { Flex, Icon, Spinner, Text, Textarea, VStack } from '@chakra-ui/react';

import { PageSectionHeading } from '../../../../../../../common/components/PageSectionHeading/PageSectionHeading';
import { useDebounce } from '../../../../../../../common/hooks/useDebounce';
import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { useUpdateInventoryCount } from '../../../../../../../services/inventory-counts';
import { useInventoryCountsContext } from '../../../../../context/hooks/use-inventory-counts-context.hook';
import { InventoryCountsFormValues } from '../../../../../model/inventory-counts-form-values';
import { InventoryCountsSubmitRequest } from '../../../../../model/inventory-counts-submit-request';
import { useReview } from '../../../InventoryCountsThirdStep/components/Review/hooks/use-review.hook';

export const AdditionalInformation = () => {
  const translations = useTranslations();
  const { additionalInformation } = useReview();
  const { currentInventoryCountId } = useInventoryCountsContext();
  const { control, setValue, getValues, watch } = useFormContext<InventoryCountsFormValues>();
  const { mutate: updateInventoryCountData, isLoading: isInventoryUpdating } = useUpdateInventoryCount();
  const watchedAdditionalInformation = watch('additionalInformation');
  const debouncedAdditionalInformation = useDebounce(watchedAdditionalInformation || '', 3000);

  const updateInventoryCount = useCallback(
    (submitRequest: InventoryCountsSubmitRequest) => {
      if (currentInventoryCountId) {
        updateInventoryCountData({
          inventoryCountId: currentInventoryCountId,
          submitRequest,
        });
      }
    },
    [currentInventoryCountId, updateInventoryCountData],
  );

  useEffect(() => {
    return () => {
      const { additionalInformation: updatedAdditionalInformation } = getValues();
      updateInventoryCount({ additionalInformation: updatedAdditionalInformation ?? null });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateInventoryCount({ additionalInformation: debouncedAdditionalInformation });
  }, [debouncedAdditionalInformation, updateInventoryCount]);

  const handleTrimValue = (event: React.FocusEvent<HTMLTextAreaElement>) =>
    setValue('additionalInformation', event.target.value.trim());

  return (
    <VStack alignItems={'start'} p={2} w={'100%'}>
      <PageSectionHeading>
        {translations('inventory_counts_additional_information_heading')}&nbsp;&nbsp;
        {isInventoryUpdating ? <Spinner size="xs" /> : <Icon as={CheckIcon} />}
      </PageSectionHeading>
      <Text fontSize={14} textAlign={'left'} py={3}>
        {translations('inventory_counts_additional_information_summary')}
      </Text>
      <Flex w={'100%'} pr={[0, 0, 0, 2]}>
        <Controller
          control={control}
          name="additionalInformation"
          defaultValue={additionalInformation || undefined}
          render={({ field: { value, onChange } }) => (
            <Textarea
              variant={'outline'}
              minH={['10em', '10em', '16em', '16em']}
              w={'100%'}
              m={0}
              pr={0}
              value={value}
              onChange={onChange}
              onBlur={handleTrimValue}
            />
          )}
        />
      </Flex>
    </VStack>
  );
};
