import React, { useCallback, useState } from 'react';
import { Center, Text, VStack } from '@chakra-ui/react';

import { PageSectionHeading } from '../../../../../../../common/components/PageSectionHeading/PageSectionHeading';
import QrCodeScanner from '../../../../../../../common/components/QrCodeScanner/QrCodeScanner';
import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { useUpdateItemsInInventoryCount } from '../../../../../../../services/inventory-counts';
import { extractUId } from '../../../../../../../utils/qrCodesHelper';
import { useInventoryCountsContext } from '../../../../../context/hooks/use-inventory-counts-context.hook';

export const Scanning = () => {
  const translations = useTranslations();
  const [showScanConfirmation, setShowScanConfirmation] = useState(false);
  const { currentInventoryCountId } = useInventoryCountsContext();
  const { mutate: updateInventoryCount } = useUpdateItemsInInventoryCount();

  const handleScan = useCallback(
    (qrCode: string) => {
      const uId = extractUId(qrCode);
      if (currentInventoryCountId && uId) {
        updateInventoryCount({ inventoryCountId: currentInventoryCountId, updateRequest: { uId } });
        setShowScanConfirmation(true);
        setTimeout(() => setShowScanConfirmation(false), 400);
      }
    },
    [currentInventoryCountId, updateInventoryCount],
  );

  return (
    <VStack alignItems={'start'} p={2}>
      <PageSectionHeading>{translations('inventory_counts_scanning_heading')}</PageSectionHeading>
      <Text fontSize={14} textAlign={'left'} py={3}>
        {translations('inventory_counts_scanning_description')}
      </Text>
      <Center w={'100%'}>
        <QrCodeScanner onScan={handleScan} showScanConfirmation={showScanConfirmation} maxWidth={'100%'} />
      </Center>
    </VStack>
  );
};
