import { Box, Flex, VStack } from '@chakra-ui/react';

import { ItemOverview } from './components/ItemOverview/ItemOverview';
import { Scanning } from './components/Scanning/Scanning';

export const InventoryCountsSecondStep = () => {
  return (
    <VStack mt={[0, 0, 0, 32]} w={'100%'}>
      <Flex w={'100%'} pt={[0, 0, 0, 6]} wrap={'wrap'}>
        <Box w={['100%', '100%', '50%', '50%']} pr={[0, 0, 4, 4]}>
          <ItemOverview />
        </Box>
        <Box w={['100%', '100%', '50%', '50%']} pl={[0, 0, 4, 4]}>
          <Scanning />
        </Box>
      </Flex>
    </VStack>
  );
};
